<!-- @Author: Yu_Bo -->
<template>
	<div class='call_up'>
    <div class="call_up_main">
      <div class="title">KOL群英征集令</div>
      <div class="tip">— 500W+粉丝KOL 免费拥有云川“虚拟数字人” —</div>
      <div class="main_con">
        <div class="con_left">
          <div class="left_title">KOL赋能计划助您入驻元宇宙空间</div>
          <div class="left_box">
            <div class="box_title">签约成功后，您即可享受以下权益</div>
            <div class="box_text">
              <img class="img" src="@/assets/images/icon/kol_icon_a.png" alt="">
              <span>赠送价值8000</span>
              <img class="icon" src="@/assets/images/icon/diamond_icon.png" alt="">
              <span>数字人定制形象 1次</span>
            </div>
            <div class="box_text">
              <img class="img" src="@/assets/images/icon/kol_icon_b.png" alt="">
              <span>赠送30分钟 通用视频制作时长</span>
            </div>
          </div>
          <div class="left_btn" @click="settleInBtn">申请入住</div>
        </div>
        <div class="con_right">
          <div class="right_video">
            <img :src="list[list_index].image" alt="">
            <!-- <video :src="list[list_index].video" loop autoplay controls></video> -->
          </div>
          <div class="right_list">
            <div class="list" :class="list_index==index?'list_active':''" v-for="(item,index) in list" :key="index">
              <img class="image" :src="item.image" alt="">
              <div class="bot">
                <img src="../../assets/images/icon/play.png" alt="">
                <span class="one-txt-cut">{{item.name}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
        list_index:0,
        list:[
          {image:require('@/assets/images/background/main_background_home.jpg'),video:'',name:'数字新局·开放共生数字数字新局·开放共生数字'},
          {image:require('@/assets/images/background/main_background_wo.jpg'),video:'',name:'穿梭未来·即刻启程与我穿梭未来·即刻启程与我'},
          {image:require('@/assets/images/background/main_background_work.jpg'),video:'',name:'如何加入KOL为此赋能如何加入KOL为此赋能…'},
        ],
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 申请入住
      settleInBtn(){
        this.$router.push('/settleIn')
      },
    },
	}
</script>

<style lang='scss' scoped>
	.call_up{
    width: 100%;
    height: 100%;
    background: url(~@/assets/images/background/kol_background.jpg) no-repeat;
    background-size: 100% 100%;
    .call_up_main{
      width: 1280px;
      margin: auto;
      .title{
        padding-top: 170px;
        width: 100%;
        text-align: center;
        font-size: 55px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }
      .tip{
        padding-top: 15px;
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        letter-spacing: 2px;
      }
      .main_con{
        width: 100%;
        padding-top: 150px;
        display: flex;
        justify-content: space-between;
        .con_left{
          width: 500px;
          .left_title{
            width: 100%;
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            letter-spacing: 2px;
          }
          .left_box{
            width: 100%;
            margin-top: 30px;
            height: 140px;
            padding: 15px 20px;
            background: rgba(255,255,255,0.07);
            .box_title{
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              letter-spacing: 1px;
            }
            .box_text{
              padding-top: 15px;
              display: flex;
              align-items: center;
              .img{
                display: block;
                width: 28px;
                height: 28px;
                margin-right: 10px;
              }
              .icon{
                display: block;
                width: 18px;
                height: 18px;
                margin: 0 5px;
              }
              span{
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                letter-spacing: 1px;
              }
            }
          }
          .left_btn{
            cursor: pointer;
            margin-top: 40px;
            width: 144px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            background: #2E4BF2;
            border-radius: 4px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
        .con_right{
          width: 540px;
          .right_video{
            width: 100%;
            height: 280px;
            video{
              display: block;
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
            img{
              display: block;
              width: 100%;
              height: 100%;
            }
          }
          .right_list{
            width: 100%;
            padding-top: 20px;
            display: flex;
            justify-content: space-between;
            .list{
              cursor: pointer;
              position: relative;
              width: 172px;
              height: 83px;
              border-radius: 5px;
              overflow: hidden;
              .image{
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                display: block;
                width: 100%;
                height: 100%;
              }
              .bot{
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 24px;
                padding: 0 10px;
                background: rgba(0,0,0,0.6);
                display: flex;
                align-items: center;
                img{
                  display: block;
                  width: 14px;
                  height: 14px;
                }
                span{
                  display: block;
                  width: 134px;
                  margin-left: 5px;
                  margin-top: -1px;
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #FFFFFF;
                }
              }
            }
            .list_active{
              border: 1px solid $blueBorderColor;
            }
          }
        }
      }
    }
	}
</style>
